<template>
  <div class="mr-2">
    <div class="block" v-if="!isMobile" :style="isDetail ? {'min-width': '35px'} : {'min-width': '59px'}">
      <p class="digit">{{ days }}</p>
      <p class="text">{{ $t('countdown.days') }}</p>
    </div>
    <div class="block" v-if="!isMobile" :style="isDetail ? {'min-width': '35px'} : {'min-width': '59px'}">
      <p class="digit">{{ hours }}</p>
      <p class="text">{{ $t('countdown.hours') }}</p>
    </div>
    <div class="block" v-if="!isMobile" :style="isDetail ? {'min-width': '35px'} : {'min-width': '59px'}">
      <p class="digit">{{ minutes }}</p>
      <p class="text">{{ $t('countdown.minutes') }}</p>
    </div>
    <div class="block" v-if="!isMobile" :style="isDetail ? {'min-width': '35px'} : {'min-width': '59px'}">
      <p class="digit">{{ seconds }}</p>
      <p class="text">{{ $t('countdown.seconds') }}</p>
    </div>
    <div class="block-m" v-if="isMobile">
      <span class="text-w">{{ days }} : {{ hours }} : {{ minutes }} : {{ seconds }}</span>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Countdown',
    props : {
      date : {
        type: Number,
        default: 0
      },
      isMobile : {
        type: Boolean,
        default: false
      },
      isDetail : {
        type: Boolean,
        default: false
      }
    },
    computed: {
      seconds() {
        if (this.date > this.now) {
          return this.digits((this.date - this.now) % 60)
        } else {
          this.$emit('finish')
          return '00'
        }
      },
      minutes() {
        if (this.date > this.now) {
          return this.digits(this.ceilValue((this.date - this.now) / 60) % 60)
        } else {
          return '00'
        }
      },
      hours() {
        if (this.date > this.now) {
          return this.digits(this.ceilValue((this.date - this.now) / 60 / 60) % 24)
        } else {
          return '00'
        }
      },
      days() {
        if (this.date > this.now) {
          return this.digits(this.ceilValue((this.date - this.now) / 60 / 60 / 24))
        } else {
          return '00'
        }
      }
    },
    data() {
      return {
        now: this.ceilValue((new Date()).getTime() / 1000)
      }
    },
    methods: {
      digits (value) {
        if (value.toString().length <= 1) {
          return "0"+value.toString()
        }
        return value.toString();
      },
      ready () {
        setInterval(() => {
          this.now = this.ceilValue((new Date()).getTime() / 1000)
        }, 1000)
      }
    },
    mounted () {
      this.ready()
    }
  }
</script>

<style scoped>
  .block-m {
    min-width: 59px;
    background-color: #000;
    display: inline-block;
    flex-direction: column;
    opacity: 0.7;
    padding: 5px;
  }

  .block {
    /* min-width: 59px; */
    background-color: #000;
    opacity: 0.7;
    display: inline-block;
    flex-direction: column;
    margin: 5px;
    padding: 10px;
  }

  .text-w {
    color: #FFF;
    font-size: 10px;
    text-align: center;
  }

  .text {
    color: #FFF;
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }

  .digit {
    color: #FFF;
    font-size: 10px;
    margin: 5px;
    text-align: center;
  }
</style>
