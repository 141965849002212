<template>
  <div id="shop" ref="shop">
    <v-flex xs12>
      <v-layout wrap class="ml-1 mr-1 mt-16" v-if="shop.length > 0">
        <section v-for="(category, i) in shop" :key="i">
          <v-layout wrap>
            <!-- <v-flex :class="index === 0 || (index+3)%3 === 0 || (category.is_featured && index === Object.keys(category.item).length - 1 && ((index-1)+3)%3 === 0 ) ? 'xs12 sm12 md12 pt-5 pb-5' : 'xs6 sm6 md6 pt-5 pb-5'" v-for="(item, index) in category.item" :key="index"> -->
            <v-flex :class="item.thumbnail === 'BIG' ? 'xs12 sm12 md12 pt-5 pb-5' : 'xs6 sm6 md6 pt-5 pb-5'" v-for="(item, index) in category.item" :key="index">
              <v-layout wrap>
                <v-flex xs12 class="shop-item pl-2 pr-2">

                  <v-carousel height="100%" :style="isMobile ?  item.thumbnail === 'BIG' ? 'min-height:400px' :'min-height:300px' : 'min-height:100vh'"
                    :hide-delimiter-background="true"
                    :hide-delimiters="true"
                    :show-arrows="isMobile ? false : true"
                    style="background-color:#fafafa;"
                    :id="'row-'+ i +'-'+ index"
                    @change="change($event, i, index)"
                  >
                    <v-carousel-item v-for="(image, ind) in item.url_images" :key="ind">
                      <v-row class="fill-height" align="center" justify="center" @click="detail(item, i, index)">
                        <!-- <img v-bind:src="image" width="100%" alt="item"> -->
                        <ImageItem class="mt-2" :source="image"></ImageItem>
                      </v-row>
                    </v-carousel-item>
                  </v-carousel>

                  <!-- <v-carousel :height="isMobile ? 'auto': '100%'" :style="isMobile ?  item.thumbnail === 'BIG' ? 'min-height:400px' :'min-height:300px' : 'min-height:100vh'" v-if="sliderProducts !== '1'"
                    :hide-delimiter-background="true"
                    :hide-delimiters="true"
                    :next-icon="false"
                    :prev-icon="false"
                    :id="'row-'+ i +'-'+ index"
                  >
                    <v-carousel-item>
                      <v-row class="fill-height" align="center" justify="center"> -->
                        <!-- <img v-bind:src="item.url_image" width="100%" alt="item"> -->
                        <!-- <ImageItem :source="item.url_image"></ImageItem>
                      </v-row>
                    </v-carousel-item>
                  </v-carousel> -->

                  <!-- <img v-if="(index+1)%3 === 0" v-bind:src="item.url_image" :class="isMobile ? 'shop-img-m2' : 'shop-img2'" alt="img"> -->
                  <!-- <img v-else v-bind:src="item.url_image" :class="isMobile ? 'shop-img-m' : 'shop-img'" alt="img"> -->

                  <!-- <span v-if="(!item.qty || item.qty === '0') && ((index+3)%3 === 0 || index === 0)" :class="isMobile ? 'shop-badge-notify-m' : 'shop-badge-notify-b'"> {{ $t('shop.sold') }} </span>
                  <span v-if="(!item.qty || item.qty === '0') && !( (index+3)%3 === 0 || index === 0)" :class="isMobile ? 'shop-badge-notify-m' : 'shop-badge-notify'"> {{ $t('shop.sold') }} </span> -->

                  <span v-if="(!item.qty || item.qty === '0') && item.thumbnail === 'BIG'" :class="isMobile ? 'shop-badge-notify-m' : 'shop-badge-notify-b'" :style="soldColor ? {'background-color': soldColor} : {'background-color': 'rgb(218,41,28)'}"> {{ $t('shop.sold') }} </span>
                  <span v-if="(!item.qty || item.qty === '0') && item.thumbnail !== 'BIG'" :class="isMobile ? 'shop-badge-notify-m' : 'shop-badge-notify'" :style="soldColor ? {'background-color': soldColor} : {'background-color': 'rgb(218,41,28)'}"> {{ $t('shop.sold') }} </span>

                  <!-- <span v-if="(item.is_coming_soon !== 'NO') && ((index+3)%3 === 0 || index === 0)" :class="isMobile ? 'shop-badge-notify-soon-m' : 'shop-badge-notify-soon-b'"> SOON </span>
                  <span v-if="(item.is_coming_soon !== 'NO') && !( (index+3)%3 === 0 || index === 0)" :class="isMobile ? 'shop-badge-notify-soon-m' : 'shop-badge-notify-soon'"> SOON </span> -->

                  <span v-if="(item.is_coming_soon !== 'NO') && item.thumbnail === 'BIG'" :class="isMobile ? 'shop-badge-notify-soon-m' : 'shop-badge-notify-soon-b'"> SOON </span>
                  <span v-if="(item.is_coming_soon !== 'NO') && item.thumbnail !== 'BIG'" :class="isMobile ? 'shop-badge-notify-soon-m' : 'shop-badge-notify-soon'"> SOON </span>

                  <Countdown v-if="item && item.countdown" class="countdown" :isMobile="isMobile" :date="getTime(item.end_at)" @finish="item.countdown = false"/>

                  <!-- <div class="page-counter">
                    {{ delimeters[i].items[index].activeIndex + 1}} / {{ delimeters[i].items[index].url_images.length}}
                  </div> -->

                </v-flex>

                <v-flex xs12 style="text-align:center" v-if="isMobile">
                  <v-icon
                    v-for="(itemp, indexp) in delimeters[i].items[index].url_images"
                    :key="indexp"
                    class="mr-1"
                    :color="indexp === delimeters[i].items[index].activeIndex ? pointerColor : '#C0C0C0'"
                    size="7"
                  >
                    mdi-checkbox-blank-circle
                  </v-icon>
                </v-flex>

                <v-flex xs12 style="text-align:center;cursor:pointer;" @click="detail(item, i, index)">
                  <p class="text"> {{ item.product_name }}</p>
                  <p v-if="item.harga_coret && item.harga_coret !== '0'" class="text-through"> {{ addComma(item.harga_coret) }} IDR </p>
                  <!-- <p class="text" style="color:#da291c"> {{ addComma(item.selling_price) }} IDR </p> -->
                  <span v-if="item.start_price && item.end_price" class="text" style="color:#da291c">
                    {{ addComma(item.start_price) }} IDR - {{ addComma(item.end_price) }} IDR
                  </span>
                  <span v-else class="text" style="color:#da291c">
                    {{ addComma(item.selling_price) }} IDR
                  </span>
                  <p class="text text-sold" v-if="item.sold_qty && item.sold_qty !== '0' && brand === '9'"> {{ item.sold_qty}} pcs terjual</p>
                </v-flex>
                <v-flex xs12 style="text-align:center;cursor:pointer;" v-if="item.variant && item.variant.length != 0 && !item.countdown && item.is_coming_soon === 'NO'">
                  <div class="shop-variant">
                    <span v-for="(item, i) in item.variant" :key="i">
                      <i :class="item.status_variant === 'sold' ? 'shop-variant-s' : 'shop-variant-a'"> {{ item.ukuran }} </i>
                    </span>
                  </div>
                </v-flex>
                <v-flex xs12 style="text-align:center;cursor:pointer;" v-if="item.is_bundling && item.is_bundling === true">
                  <div class="shop-variant">
                    <span>
                      <i :class="item.status === 'sold' ? 'shop-variant-s' : 'shop-variant-a'"> BUNDLING </i>
                    </span>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </section>
      </v-layout>
      <v-layout wrap v-if="isEmpty">
        <img src="@/assets/stock-kosong.jpg" width="40%" class="shop-img-empty" @click="toPage('Home')">
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import Countdown from '@/components/Countdown'
  import ImageItem from '@/components/ImageItem'

  export default {
    name: 'Shop',
    components: { Countdown, ImageItem },
    computed: {
      ...mapState([
        'isLoading',
        'isMobile',
        'cartNumber',
        'category',
        'productType',
        'yIndex',
        'footer'
      ]),
      category () {
        return this.$route.params.id
      }
    },
    watch: {
      category (val) {
        this.$store.commit('setActivePage', val.toUpperCase())
        this.setMetaInfo(null, this.$route.params.id)
        this.getCategory()
      }
    },
    data () {
      return {
        brand: process.env.VUE_APP_BRAND,
        sliderProducts: process.env.VUE_APP_SLIDER_PRODUCT,
        shop: [],
        message: '',
        isEmpty: false,
        soldColor: process.env.VUE_APP_LABEL_SOLD,
        pointerColor: '',
        delimeters: null
      }
    },
    methods: {
      scrollToTop() {
        setTimeout(()=> {
          window.scrollTo(0,0)
        }, 1000)
      },

      scrollIntoView () {
        let id = 'row-' + this.yIndex

        setTimeout(()=> {
          var element = document.getElementById(id);
          element.scrollIntoView({
            behaviour: 'auto',
            block: 'center',
            inline: 'center'
          });
          // element.scrollIntoView({behavior: "smooth"});
          this.$store.commit('setYIndex', '0-0')
        })
      },
      change ($event, i, index) {
        this.delimeters[i].items[index].activeIndex = $event
      },
      detail (item, i, index) {
        this.$store.commit('setYIndex', ''+ i + '-' + index)
        this.toProduct(item)
      },
      getTime (value) {
        let t = value.split(/[- :]/);
        // Apply each element to the Date function
        let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
        return this.ceilValue((new Date(d)).getTime() / 1000)
      },
      getCategory () {
        // this.scrollToTop()
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          category: this.category,
          type: this.productType || this.$route.params.category,
          is_offline: true
        }
        axios.get('/api/v1/pages/category')
          .then(response => {
            this.shop = response.data.data
            this.isEmpty = response.data && response.data.data.length > 0 ? false : true
            // this.setList(response.data.data)
            // this.productImpressions(response.data.data)
            this.scrollIntoView()
            this.setDelimeters(response.data.data)
          })
          .catch(error => {
            this.shop = []
            this.message = error.response.data.api_msg
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      setDelimeters (data) {
        let items = this.setList(data)
        this.delimeters = items
      },
      productImpressions (response) {
        window.dataLayer.push({
          'currencyCode': 'IDR',
          'event': 'impressions',
          'ecommerce': {
            'impressions': this.setImpressionData(response)
          }
        });
      },
      setImpressionData (response) {
        let position = 0
        let datas = []
        response.map(item => {
          item.item.map(data => {
            position++
            datas.push({
              'name': data.product_name,
              'id': data.product_code,
              'price': data.selling_price,
              'brand': this.brand,
              'category': data.category,
              'variant': data.product_name,
              'list': 'Product List',
              'position': position
            })
          })
        })
        return datas
      },
      // setList (response) {
      //   response.map((item,i) => {
      //     item.item.map((data, index) => {
      //       this.shop.push(data)
      //       if (index === response[i].item.length - 1) {
      //         this.shop.push({})
      //       }
      //     })
      //   })
      // },
      setList (response) {
        let items = []
        response.map((item,i) => {
          items.push({
            category: item.category,
            items: item.item.map((data, index) => {
              return  {
                  id: 'row-'+ i +'-'+ index,
                  activeIndex: 0,
                  url_images: data.url_images
              }
            })
          })
        })
        return items
      }
    },
    // beforeRouteLeave: function(to, from, next) {
    //   this.$store.commit('setCanBack', true)
    //   next();
    // },
    mounted () {
      this.getCategory()
      this.setMetaInfo(null, this.$route.params.id)
      this.pointerColor = this.footer && this.footer.color.background ? this.footer.color.background : '#000'

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setActivePage', this.category.toUpperCase())
    }
  }
</script>

<style scoped>
  p {
    margin: 0;
    padding: 0;
  }

  .shop-item {
    position: relative;
    text-align: center;
  }

  .shop-badge-notify{
    /* opacity: 0.5; */
    background-color: rgb(218,41,28);
    color: #FFFFFF;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    top: 2%;
    left: 5%;
    /* transform: translate(-50%, 0); */
  }

  .shop-badge-notify-b{
    /* opacity: 0.5; */
    background-color: rgb(218,41,28);
    color: #FFFFFF;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    top: 1%;
    left: 2%;
    /* transform: translate(-50%, 0); */
  }

   .shop-badge-notify-m {
    background-color: rgb(218,41,28);
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    top: 2%;
    left: 6%;
  }

  .shop-badge-notify-soon{
    /* opacity: 0.5; */
    background-color: #e7a635;
    color: #FFFFFF;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    top: 2%;
    left: 5%;
    /* transform: translate(-50%, 0); */
  }

  .shop-badge-notify-soon-b{
    /* opacity: 0.5; */
    background-color: #e7a635;
    color: #FFFFFF;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    top: 1%;
    left: 2%;
    /* transform: translate(-50%, 0); */
  }

   .shop-badge-notify-soon-m {
    background-color: #e7a635;
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    top: 2%;
    left: 6%;
  }

  .shop-img {
    max-width: 100%;
    height: auto;
    /* width: 450px;
    height: 450px;
    object-fit: cover; */
    cursor: pointer;
  }

  .shop-img2 {
    max-width: 100%;
    height: auto;
    /* height: 750px;
    object-fit: cover; */
    cursor: pointer;
  }

  .shop-img:hover {
    opacity: 0.5;
  }

  .shop-img-empty {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shop-img-m {
    max-width: 100%;
    height: auto;
    /* width: 170px;
    height: 180px;
    object-fit: cover; */
  }

  .shop-img-m2 {
    max-width: 100%;
    height: auto;
    /* height: 325px; */
    /* object-fit: cover; */
  }

  .text{
    font-size: 0.8rem;
  }

  .text-sold {
    color: #000 !important;
  }

  .text-through{
    font-size: 0.8rem;
    color: #000000;
    text-decoration: line-through;
  }

  .countdown {
    position: absolute;
    width: 100%;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .shop-variant {
    font-size: 0.8rem;
  }

  .shop-variant-s {
    color: #d3d3d3;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: bold;
  }

  .shop-variant-a {
    color: #000;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: bold;
  }
  .v-window-x-transition-enter-active, .v-window-x-reverse-transition-enter-active, .v-window-y-transition-enter-active, .v-window-y-reverse-transition-enter-active, .v-window-x-transition-leave-active, .v-window-x-reverse-transition-leave-active, .v-window-y-transition-leave-active, .v-window-y-reverse-transition-leave-active {
    transition:ease-in 300ms !important;
  }
</style>
