<template>
  <figure v-lazyload class="img-lazyload">
    <img
      :data-url="source"
      alt="random image"
      :style="customStyle && customStyle != '' ? {customStyle} : ''"
      :class="[customClass ? customClass : '', 'img-item']"
    >
    <div class="loader"></div>
  </figure>
</template>

<script>
  export default {
    name: "ImageItem",
    props: {
      source: {
        type: String,
        required: true
      },
      customStyle: {
        type: String,
        required: false
      },
      customClass: {
        type: String,
        required: false
      }
    }
  };
</script>

<style scoped>
  .img-item {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .loader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: 
    linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(#eee, #eee);  
    animation: loading 1.5s infinite;
  }

  @keyframes loading {  
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }

  .hidden {
    visibility: hidden;
  }
</style>